<template>
  <autocomplete-single name="Филиал" :items="arr" :select.sync="localSelect" @load="load" :loading="api.loading">
    <template v-slot:itemIcon="{item}">
      <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-3px"/>
    </template>
    <template v-slot:selectedInfo="{item}">
      <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-2px"/> {{item.name}}
    </template>
  </autocomplete-single>

</template>

<script>

import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import GraphqlApi from "@/plugins/graphqlApi";

export default {
  components: {AutocompleteSingle},
  props:[
    'select','globalState'
  ],
  data:()=>({
    api: new GraphqlApi(),
    arr: [],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
      if(typeof this.globalState!=='undefined'){
        let arr = [this.localSelect];
        arr.forEach((v)=>{
          v.id = parseInt(v.id);
        });
        localStorage['globalState_comBranchs'] = JSON.stringify(arr);
      }
    }
  },
  mounted() {
    this.localSelect = this.select;
    if(typeof this.globalState!=='undefined' && typeof localStorage['globalState_comBranchs']!=='undefined') {
      let arr = JSON.parse(localStorage['globalState_comBranchs']);
      if(arr.length>0){
        arr.forEach((v)=>{
          v.id = parseInt(v.id);
        });
        this.localSelect = arr[0];
        this.load();
      }
    } else if(typeof this.localSelect!=='undefined' && this.arr.length===0){
      this.load();
    }
  },
  methods:{
    load(){
      this.api.request(
        '/api/graphql/',
        `
          query Query{
            ComBranch(sortBy:"name",sortDesc:false){
              id
              name
              color
            }
          }
        `,
        {},
        (r)=>{
          this.arr = r.ComBranch;
          this.arr.forEach(v=>{
            v.id = parseInt(v.id);
          })
        },
        ()=>{
          this.$store.commit('alertError', 'Ошибка в работе МИС. Если ошибка повторяется свяжитесь с тех. подержкой');
        },
        true
      )
    }
  }
}
</script>