<template>
  <v-card class="ma-6" :loading="medRecordTemplatesState.isLoading">
    <v-tabs v-model="medRecordTemplatesIndex">
      <v-tab v-for="(spec, key) in medRecordTemplates" :key="key">
        {{ spec.name }}
      </v-tab>
      <v-tab-item v-for="(spec, key) in medRecordTemplates" :key="key">
        <v-row no-gutters class="px-4 py-2">
          <v-col>
            <v-btn
                color="primary" depressed class="mr-2" small
                @click="medRecordTemplateCategoryCreateState.modalOpen(); medRecordTemplateCategoryCreate.specId=spec.id">
              Создать категорию
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels accordion v-model="medRecordTemplateCategorysOpens[medRecordTemplatesIndex]">
          <v-expansion-panel v-for="(medRecordTemplateCategory, key) in spec.medRecordTemplateCategorys" :key="key">
            <v-expansion-panel-header>{{ medRecordTemplateCategory.name }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n6">
              <v-row no-gutters class="mx-4 my-2">
                <v-col>
                  <v-btn
                      depressed class="mr-2" small
                      @click="
                        medRecordTemplateCategoryUpdateState.modalOpen();
                        medRecordTemplateCategoryUpdate.id=medRecordTemplateCategory.id;
                        medRecordTemplateCategoryUpdate.specId=spec.id;
                        medRecordTemplateCategoryUpdate.name=medRecordTemplateCategory.name;
                        medRecordTemplateCategoryUpdate.sort=medRecordTemplateCategory.sort;
                      ">
                    Изменить категорию
                  </v-btn>
                  <v-btn
                      depressed class="mr-2" small
                      @click="medRecordTemplateCategoryDeleteState.modalOpen(medRecordTemplateCategory)">
                    Удалить категорию
                  </v-btn>
                  <v-btn
                      color="primary" depressed class="mr-2" small
                      @click="medRecordTemplateCreate(medRecordTemplateCategory.id)"
                      :loading="medRecordTemplateCreateState.isLoading"
                  >Создать шаблон</v-btn>
                </v-col>
              </v-row>
              <v-data-table
                  :headers="medRecordTemplatesHeaders"
                  :items="medRecordTemplateCategory.medRecordTemplates"
                  :items-per-page="-1"
                  dense>
                <template v-slot:item="{ item }">
                  <tr @click="medRecordTemplateId=item.id">
                    <td class="text-no-wrap">{{item.id}}</td>
                    <td class="text-no-wrap">{{item.name}}</td>
                    <td class="text-no-wrap">{{item.sort}}</td>                    
                    <v-tooltip v-if="item.status==='unactive'" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                      </template>
                      <span>Черновик</span>
                    </v-tooltip>
                  </tr>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="medRecordTemplateCategoryCreateState.isModalOpen" width="500">
      <v-form ref="medRecordTemplateCategoryCreateForm" @submit.prevent="medRecordTemplateCategoryCreateAction()">
        <v-card>
          <v-card-title>Создать категорию</v-card-title>
          <v-card-text>
            <v-textarea label="Название" v-model="medRecordTemplateCategoryCreate.name" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            <v-textarea label="Сортировка" v-model="medRecordTemplateCategoryCreate.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="medRecordTemplateCategoryCreateState.modalClose()">Отмена</v-btn>
            <v-btn color="primary" text type="submit" :loading="medRecordTemplateCategoryCreateState.isLoading">
              Создать
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="medRecordTemplateCategoryUpdateState.isModalOpen" width="500">
      <v-form ref="medRecordTemplateCategoryUpdateForm" @submit.prevent="medRecordTemplateCategoryUpdateAction()">
        <v-card>
          <v-card-title>Изменить категорию</v-card-title>
          <v-card-text>
            <v-textarea label="Название" v-model="medRecordTemplateCategoryUpdate.name" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            <v-textarea label="Сортировка" v-model="medRecordTemplateCategoryUpdate.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="medRecordTemplateCategoryUpdateState.modalClose()">Отмена</v-btn>
            <v-btn color="primary" text type="submit" :loading="medRecordTemplateCategoryUpdateState.isLoading">
              Изменить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="medRecordTemplateCategoryDeleteState.isModalOpen" width="250">
      <v-card>
        <v-card-title>Удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="medRecordTemplateCategoryDeleteState.modalClose()">Отмена</v-btn>
          <v-btn
              color="red" text
              @click="medRecordTemplateCategoryDelete(medRecordTemplateCategoryDeleteState.modalData)"
              :loading="medRecordTemplateCategoryDeleteState.isLoading">
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <med-record-template-update :med-record-template-id.sync="medRecordTemplateId" @medRecordTemplateCopied="medRecordTemplateCopied" />
  </v-card>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import {FormFieldRules} from "@/plugins/formFieldRules";
import MedRecordTemplateUpdate from "@/views/MedRecord/MedRecordTemplateUpdate";

export default {
  components: {MedRecordTemplateUpdate},
  data: () => ({
    medRecordTemplatesState: new State(),
    medRecordTemplates: [],
    medRecordTemplatesIndex: 0,

    medRecordTemplateCategorysOpens: [],

    formFieldRules: FormFieldRules,
    medRecordTemplateCategoryCreateState: new State(),
    medRecordTemplateCategoryCreate:{
      specId:null,
      name:null,
      sort:null
    },
    medRecordTemplateCategoryUpdateState: new State(),
    medRecordTemplateCategoryUpdate:{
      id:null,
      specId:null,
      name:null,
      sort:null
    },

    medRecordTemplateCategoryDeleteState: new State(),

    medRecordTemplatesHeaders: [
      { text: 'ID', value: 'id', sortable: false, class:'text-no-wrap'},
      { text: 'Название', value: 'name', sortable: false, class:'text-no-wrap'},
      { text: 'Сортировка', value: 'sort', sortable: true, class:'text-no-wrap'}     
    ],

    medRecordTemplateCreateState: new State(),
    medRecordTemplateId:null,
  }),
  mounted() {
    this.load();
  },
  watch:{
    medRecordTemplateId(){
      if (this.medRecordTemplateId==null)
        this.load();
    }
  },
  methods:{
    load(){
      this.medRecordTemplatesState.stateLoading();
      Api.service.medRecord.medRecordTemplateCategorys(1, 100, false, (data)=>{
        this.medRecordTemplatesState.stateSuccess();

        let medRecords = [];

        data.data.forEach((v)=>{
            v.medRecordTemplateCategorys.forEach((v1)=> {
              v1.medRecordTemplates.sort((a, b) => a.sort - b.sort);
              medRecords.push(v); 
            });
        });

        data.data = medRecords;
        this.medRecordTemplates = data.data;
      }, ()=>{
        this.medRecordTemplatesState.stateError();
      });
    },
    medRecordTemplateCreate(medRecordTemplateCategoryId){
      this.medRecordTemplateCreateState.stateLoading();
      Api.service.medRecord.medRecordTemplateCreate(medRecordTemplateCategoryId, (data)=>{
        this.medRecordTemplateCreateState.stateSuccess();
        this.medRecordTemplateId = data.id;
        this.load();
      }, ()=>{
        this.medRecordTemplateCreateState.stateError();
      });
    },
    medRecordTemplateCategoryDelete(medRecordTemplatesCategory){
      this.medRecordTemplateCategoryDeleteState.stateLoading();
      Api.service.medRecord.medRecordTemplateCategoryDelete(medRecordTemplatesCategory.id, ()=>{
        this.medRecordTemplateCategoryDeleteState.stateSuccess();
        this.medRecordTemplateCategoryDeleteState.modalClose();
        this.load();
      }, ()=>{
        this.medRecordTemplateCategoryDeleteState.stateError();
      });
    },
    medRecordTemplateCategoryCreateAction(){
      if (this.$refs.medRecordTemplateCategoryCreateForm.validate()) {
        this.medRecordTemplateCategoryCreateState.stateLoading();
        Api.service.medRecord.medRecordTemplateCategoryCreate(this.medRecordTemplateCategoryCreate, ()=>{
          this.medRecordTemplateCategoryCreateState.stateSuccess();
          this.medRecordTemplateCategoryCreateState.modalClose();
          this.load();
        }, ()=>{
          this.medRecordTemplateCategoryCreateState.stateError();
        });
      }
    },
    medRecordTemplateCategoryUpdateAction(){
      if (this.$refs.medRecordTemplateCategoryUpdateForm.validate()) {
        this.medRecordTemplateCategoryUpdateState.stateLoading();
        Api.service.medRecord.medRecordTemplateCategoryUpdate(this.medRecordTemplateCategoryUpdate, ()=>{
          this.medRecordTemplateCategoryUpdateState.stateSuccess();
          this.medRecordTemplateCategoryUpdateState.modalClose();
          this.load();
        }, ()=>{
          this.medRecordTemplateCategoryUpdateState.stateError();
        });
      }
    },
    medRecordTemplateCopied(id){
      this.load();
      this.medRecordTemplateId = id;
    }
  }
}
</script>
