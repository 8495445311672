<template>
  <v-card class="ma-6 pa-4">
    <v-row>
        <v-col cols="3">
          <v-sheet :height="height" class="overflow-auto">
            <v-treeview
                :items="items"
                :active.sync="itemVid"
                item-key="vid"
                expand-icon="mdi-chevron-down"
                activatable
                hoverable
                dense
                rounded
                class="settingsTree pb-10"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.type==='comBranch'" small class="pl-1">{{open ? 'mdi-folder-home' : 'mdi-folder-home-outline'}}</v-icon>
                <v-icon v-if="item.type==='comBuildings'" small class="pl-1">{{open ? 'mdi-folder' : 'mdi-folder-outline'}}</v-icon>
                <v-icon v-if="item.type==='comBuilding'" small class="pl-1">{{open ? 'mdi-folder-marker' : 'mdi-folder-marker-outline'}}</v-icon>
                <v-icon v-if="item.type==='comDivs'" small class="pl-1">{{open ? 'mdi-folder' : 'mdi-folder-outline'}}</v-icon>
                <v-icon v-if="item.type==='comDiv'" small class="pl-1">{{open ? 'mdi-folder-table' : 'mdi-folder-table-outline'}}</v-icon>
                <v-icon v-if="item.type==='comFloor'" small class="pl-1">{{open ? 'mdi-folder-text' : 'mdi-folder-text-outline'}}</v-icon>
                <v-icon v-if="item.type==='comRoom'" small class="pl-1">{{open ? 'mdi-briefcase' : 'mdi-briefcase-outline'}}</v-icon>
                <v-icon v-if="item.type==='comPlace'" small class="pl-1">mdi-map-marker-outline</v-icon>
                <v-icon v-if="item.type==='cashBox'" small class="pl-1">mdi-cash-register</v-icon>
                <v-icon v-if="item.type==='create'" small class="pl-1">mdi-plus-circle-outline</v-icon>
                <v-icon v-if="item.type==='comPlaceAttach'" small class="pl-1">mdi-link-plus</v-icon>
                <v-icon v-if="item.type.indexOf('Create')!==-1" small class="pl-1">mdi-plus</v-icon>
              </template>
              <template v-slot:label="{ item }">
                <span v-if="item.type==='comRoom'" class="body-2 pr-1">{{item.sid}}</span>
                <span v-if="item.type==='comPlace'" class="body-2 pr-1">(Кабинет {{item.comRoom.sid}})</span>
                <span class="body-2">{{item.name}}</span>
              </template>
            </v-treeview>
          </v-sheet>            
        </v-col>
        <v-col>
          <v-divider class="float-left" vertical/>
          <div class="float-left pl-4 full-width">
            <alert-view v-if="item===null" icon="mdi-information-outline" text="Выберите элемент из дерева"/>
            <div v-else>
              <div class="subtitle-1">{{item.name}}</div>
              <div class="pb-2 mt-n1 caption">{{typeof item.id==='undefined' ? '' : '#'+item.id}}
                <span v-if="item.type==='comBranch'">Филиал</span>
                <span v-if="item.type==='comDiv'">Отделение</span>
                <span v-if="item.type==='comBuilding'">Строение</span>
                <span v-if="item.type==='comFloor'">Этаж</span>
                <span v-if="item.type==='comRoom'">Кабинет</span>
                <span v-if="item.type==='comPlace'">Рабочее место</span>
                <span v-if="item.type==='cashBox'">Касса</span>
              </div>
              <v-divider class="mb-4"/>
              <div v-if="item.type==='comBranch'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
                <label-view title="Цвет">
                  <template v-slot:content>
                    <v-color-picker v-model="item.form.color" mode="hexa" :hide-canvas="false" hide-inputs :hide-sliders="false" show-swatches :swatches="pickerColorSwatches"/>
                    <work-plan-view :data.sync="item.workPlan" class="mb-4" />
                  </template>
                </label-view>
              </div>
              <div v-if="item.type==='comBranchCreate'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
                <label-view title="Цвет">
                  <template v-slot:content>
                    <v-color-picker v-model="item.form.color" mode="hexa" :hide-canvas="false" hide-inputs :hide-sliders="false" show-swatches :swatches="pickerColorSwatches"/>
                  </template>
                </label-view>
              </div>
              <div v-if="item.type==='comDiv'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comDivCreate'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comBuilding'">
                <v-text-field v-model="item.form.street" label="Адрес" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comBuildingCreate'">
                <v-text-field v-model="item.form.street" label="Адрес" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comFloor'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comFloorCreate'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comRoom'">
                <v-row>
                  <v-col cols="auto">
                    <v-text-field v-model="item.form.sid" label="Номер" type="text" class="mb-4" outlined dense hide-details="auto"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
                  </v-col>
                </v-row>
              </div>
              <div v-if="item.type==='comRoomCreate'">
                <v-row>
                  <v-col cols="auto">
                    <v-text-field v-model="item.form.sid" label="Номер" type="text" class="mb-4" outlined dense hide-details="auto"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
                  </v-col>
                </v-row>
              </div>
              <div v-if="item.type==='comPlace'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comPlaceCreate'">
                <v-text-field v-model="item.form.name" label="Название" type="text" class="mb-4" outlined dense hide-details="auto"/>
              </div>
              <div v-if="item.type==='comPlaceAttach'">
                <v-row>
                  <v-col>
                    <v-autocomplete
                        v-model="comRoomId"
                        :items="comRooms"
                        :search-input.sync="comRoomSearch"
                        label="Выберите кабинет"
                        outlined
                        dense
                        hide-details="auto"
                        class="mb-4"
                        item-value="id"
                        item-text="aid"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Кабинет не найден</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{item}">
                        <v-list-item-title>
                          <div class="py-1 mr-2">
                            <v-avatar color="primary" size="26" class="position-absolute mt-2">
                              <div class="white--text caption text-center">{{item.sid}}</div>
                            </v-avatar>
                            <div class="pl-9 pt-1">
                              <div class="body-2">{{item.name}}</div>
                              <div class="caption mt-n1">
                                Этаж {{item.comFloor.name}} /
                                {{item.comBuilding.street}} /
                                {{item.comBranch.name}}
                              </div>
                            </div>
                          </div>
                        </v-list-item-title>
                      </template>
                      <template v-slot:selection="{item}">
                        <div class="py-1 mr-2">
                          <v-avatar color="primary" size="26" class="position-absolute mt-2">
                            <div class="white--text caption text-center">{{item.sid}}</div>
                          </v-avatar>
                          <div class="pl-9 pt-1">
                            <div class="body-2">{{item.name}}</div>
                            <div class="caption mt-n1">
                              Этаж {{item.comFloor.name}} /
                              {{item.comBuilding.street}} /
                              {{item.comBranch.name}}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                        v-model="comPlaceId"
                        :items="comPlacesVirtual"
                        :search-input.sync="comPlaceSearch"
                        label="Выберите рабочее место"
                        outlined
                        dense
                        hide-details="auto"
                        class="mb-4"
                        item-value="id"
                        item-text="name"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Рабочее место не найдено</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{item}">
                        <v-list-item-title>
                          <div class="py-2">
                            <div class="body-2 pt-1">{{item.name}}</div>
                          </div>
                        </v-list-item-title>
                      </template>
                      <template v-slot:selection="{item}">
                        <div class="body-2">{{item.name}}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <dialog-view
                  v-if="typeof item.id!=='undefined'"
                  title="Удалить?"
                  acceptName="Удалить"
                  @acceptAction="del(item.id)"
                  :loading="deleteState.isLoading"
              >
                <template v-slot:activator>
                  <v-btn color="error" depressed class="mr-2" dark>Удалить</v-btn>
                </template>
              </dialog-view>
              <v-btn v-if="item.type==='comPlaceAttach'" depressed color="primary" @click="attachComPlace()" :loading="comPlaceAttachState.isLoading">Привязать</v-btn>
              <v-btn v-else depressed color="primary" @click="createUpdate()" :loading="createUpdateState.isLoading">Сохранить</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
  </v-card>

</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import AlertView from "@/components/AlertView";
import colors from "vuetify/lib/util/colors";
import LabelView from "@/components/LabelView";
import DialogView from "@/components/DialogView";
import WorkPlanView from "@/components/WorkPlanView.vue";

export default {
  components: {WorkPlanView, DialogView, LabelView, AlertView},
  data: () => ({
    height: 600,
    items: [],
    itemVid: [],
    item: null,

    pickerColorSwatches:[],
    comRooms: [],
    comRoomId: null,
    comRoomSearch: null,

    comPlaces: [],
    comPlaceId: null,
    comPlaceSearch: '',

    createUpdateState: new State(),
    deleteState: new State(),
    comPlaceAttachState: new State(),
  }),
  mounted() {
    this.resize();
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);
    let pickerColors = [];
    Object.keys(colors).map(key => {
      const color = colors[key];
      if ([
        'red',
        //'pink',
        //'purple',
        //'deepPurple',
        //'indigo',
        'blue',
        //'lightBlue',
        //'cyan',
        //'teal',
        'green',
        //'lightGreen',
        //'lime',
        //'yellow',
        'amber',
        //'orange',
        //'deepOrange',
        //'brown',
        //'blueGrey',
        'grey',
      ].indexOf(key) > -1)
        pickerColors.push([
          //color.darken4,
          //color.darken3,
          //color.darken2,
          //color.darken1,
          color.base,
          //color.lighten1,
          //color.lighten2,
          color.lighten3,
          //color.lighten4,
          //color.lighten5,
        ]);
    });
    this.pickerColorSwatches = pickerColors;
    this.load();
  },
  watch:{
    itemVid(){
      if(this.itemVid.length>0){
        let vid = this.itemVid[0];
        let item = null;
        this.items.forEach(comBranch=>{
          if(vid===comBranch.vid) item=comBranch;
          if(comBranch.children.length>0){
            comBranch.children[0].children.forEach(comDiv=>{
              if(vid===comDiv.vid) item=comDiv;
              comDiv.children.forEach(comPlace=>{
                if(vid===comPlace.vid) item=comPlace;
              });
            });
            comBranch.children[1].children.forEach(comBuilding=>{
              if(vid===comBuilding.vid) item=comBuilding;
              comBuilding.children.forEach(comFloor=>{
                if(vid===comFloor.vid) item=comFloor;
                comFloor.children.forEach(comRoom=>{
                  if(vid===comRoom.vid) item=comRoom;
                  comRoom.children.forEach(comPlace=>{
                    if(vid===comPlace.vid) item=comPlace;
                  });
                });
              });
            });
          }
        });
        if(item===null){
          this.itemVid = [];
        } else {
          if(typeof item.id!=='undefined'){
            let item1 = {};
            Object.keys(item).forEach((k)=>{
              if(typeof item[k]==='boolean' || typeof item[k]==='number' || typeof item[k]==='string')
                item1[k] = item[k];
            });
            item.form = item1;
          }
          this.item = item;
        }
      }
    },
  },
  computed:{
    comPlacesVirtual:{
      get(){
        let comPlaces = [];
        if(this.comRoomId!==null)
          this.comRooms.forEach(comRoom1=>{
            if(this.comRoomId===comRoom1.id)
              comPlaces = comRoom1.comPlaces;
          });
        return comPlaces;
      },
      set(){}
    }
  },
  methods:{
    load(){
      axios.get(Api.host+'/com/struct/')
        .then(r =>{
          let index = 0;
          this.items = [
            {
              vid: index++,
              name:'Создать филиал',
              type: 'comBranchCreate',
              children:[],
              form:{name:null,color:'#FFF'}
            }, ...this.$tools.arrayGen(r.data.comBranchs, comBranch=>{
              return {
                vid: index++,
                type: 'comBranch',
                children: (() => {
                  return [
                    {
                      vid: index++,
                      name: 'Отделения',
                      type: 'comDivs',
                      children: [
                        {
                          vid: index++,
                          name:'Создать отделение',
                          type: 'comDivCreate',
                          children:[],
                          form:{name:null,comBranchId:comBranch.id}
                        }, ...this.$tools.arrayGen(comBranch.comDivs, comDiv=> {
                        return {
                          vid: index++,
                          type: 'comDiv',
                          children: (()=>{
                            let comPlaces = [];
                            comBranch.comBuildings.forEach(comBuilding=>{
                              comBuilding.comFloors.forEach(comFloor=>{
                                comFloor.comRooms.forEach(comRoom=>{
                                  comRoom.comPlaces.forEach(comPlace=>{
                                    comDiv.comPlaces.forEach(comPlace1=>{
                                      if(comPlace.id===comPlace1.id)
                                        comPlaces.push(Object.assign(comPlace, {
                                          comRoom:comRoom
                                        }));
                                    });
                                  });
                                });
                              });
                            });
                            return [
                              {
                                vid: index++,
                                name:'Привязать рабочее место',
                                type: 'comPlaceAttach',
                                children:[],
                                form:{comDivId:comDiv.id}
                              }, ...this.$tools.arrayGen(comPlaces, ()=>{
                              return {
                                vid: index++,
                                type: 'comPlace',
                              }
                            })];
                          })()
                        }
                      })]
                    },
                    {
                      vid: index++,
                      name: 'Строения',
                      type: 'comBuildings',
                      children: [
                        {
                          vid: index++,
                          name:'Создать cтроение',
                          type: 'comBuildingCreate',
                          children:[],
                          form:{street:null,comBranchId:comBranch.id}
                        }, ...this.$tools.arrayGen(comBranch.comBuildings, comBuilding=>{
                        return {
                          vid: index++,
                          name: comBuilding.street,
                          type: 'comBuilding',
                          children: [
                            {
                              vid: index++,
                              name:'Создать этаж',
                              type: 'comFloorCreate',
                              children:[],
                              form:{name:null,comBuildingId:comBuilding.id}
                            }, ...this.$tools.arrayGen(comBuilding.comFloors, comFloor=>{
                            return {
                              vid: index++,
                              type: 'comFloor',
                              children: [
                                {
                                  vid: index++,
                                  name:'Создать кабинет',
                                  type: 'comRoomCreate',
                                  children:[],
                                  form:{sid:null,name:null,comFloorId:comFloor.id}
                                }, ...this.$tools.arrayGen(comFloor.comRooms, comRoom=>{
                                return {
                                  vid: index++,
                                  type: 'comRoom',
                                  children: [
                                    {
                                      vid: index++,
                                      name:'Создать рабочее место',
                                      type: 'comPlaceCreate',
                                      children:[],
                                      form:{name:null,comRoomId:comRoom.id}
                                    }, ...this.$tools.arrayGen(comRoom.comPlaces, ()=>{
                                      return {
                                        vid: index++,
                                        type: 'comPlace',
                                        comRoom:comRoom
                                      }
                                    })
                                  ]
                                }
                              })]
                            }
                          })]
                        }
                      })]
                    },
                  ]
                })()
              }
            })
          ];

          this.comRooms = [];
          this.items.forEach(comBranch=>{
            if(comBranch.children.length>0){
              comBranch.children[1].children.forEach(comBuilding=>{
                comBuilding.children.forEach(comFloor=>{
                  comFloor.children.forEach(comRoom=>{
                    if(typeof comRoom.id!=='undefined')
                      this.comRooms.push(Object.assign(comRoom, {
                        aid:comRoom.sid+' '+comRoom.name,
                        comFloor:comFloor,
                        comBuilding:comBuilding,
                        comBranch:comBranch,
                      }));
                  });
                });
              });
            }
          });
        })
        .catch(() =>{
        });
    },

    createUpdate(){
      this.createUpdateState.stateLoading();
      let url = Api.host;
      if(this.item.type==='comBranch' || this.item.type==='comBranchCreate') url+='/comBranch/';
      if(this.item.type==='comDiv' || this.item.type==='comDivCreate') url+='/comDiv/';
      if(this.item.type==='comBuilding' || this.item.type==='comBuildingCreate') url+='/comBuilding/';
      if(this.item.type==='comFloor' || this.item.type==='comFloorCreate') url+='/comFloor/';
      if(this.item.type==='comRoom' || this.item.type==='comRoomCreate') url+='/comRoom/';
      if(this.item.type==='comPlace' || this.item.type==='comPlaceCreate') url+='/comPlace/';
      if(this.item.type==='cashBox' || this.item.type==='cashBoxCreate') url+='/cashBox/';

      if(typeof this.item.id==='undefined') {
        axios.post(url, this.item.form).then(()=>{
          this.item=null;
          this.load();
          this.$store.commit('alertSuccess', 'Успешно сохранено');
          this.createUpdateState.stateSuccess();
        }).catch(()=>{
          this.createUpdateState.stateError();
        });
      } else {
        if(this.item.type==='comBranch')
          this.item.form.workPlan = this.item.workPlan;
        axios.put(url+this.item.id+'/', this.item.form).then(()=>{
          this.load();
          this.$store.commit('alertSuccess', 'Успешно сохранено');
          this.createUpdateState.stateSuccess();
        }).catch(()=>{
          this.createUpdateState.stateError();
        });
      }
    },
    del(id){
      this.deleteState.stateLoading();
      let url = Api.host;
      if(this.item.type==='comBranch' || this.item.type==='comBranchCreate') url+='/comBranch/';
      if(this.item.type==='comDiv' || this.item.type==='comDivCreate') url+='/comDiv/';
      if(this.item.type==='comBuilding' || this.item.type==='comBuildingCreate') url+='/comBuilding/';
      if(this.item.type==='comFloor' || this.item.type==='comFloorCreate') url+='/comFloor/';
      if(this.item.type==='comRoom' || this.item.type==='comRoomCreate') url+='/comRoom/';
      if(this.item.type==='comPlace' || this.item.type==='comPlaceCreate') url+='/comPlace/';
      if(this.item.type==='cashBox' || this.item.type==='cashBoxCreate') url+='/cashBox/';

      axios.delete(url+id+'/').then(()=>{
        this.$store.commit('alertSuccess', 'Успешно удалено');
        this.itemVid=[];
        this.item=null;
        this.load();
        this.deleteState.stateSuccess();
      }).catch((e)=>{
        this.itemVid=[];
        this.item=null;
        this.load();
        this.$store.commit('alertError', e.response.data.message);
        this.deleteState.stateError();
      });
    },


    attachComPlace(){
      this.comPlaceAttachState.stateLoading();
      axios.put(Api.host+'/comPlace/'+this.comPlaceId+'/', {
        comDivId:this.item.form.comDivId
      }).then(()=>{
        this.itemVid=[];
        this.item=null;
        this.load();
        this.$store.commit('alertSuccess', 'Успешно привязано');
        this.comPlaceAttachState.stateSuccess();
      }).catch(()=>{
        this.comPlaceAttachState.stateError();
      });
    },

    resize(){
      this.height = window.innerHeight-185;
    },
  }
}
</script>

<style lang="scss">
.settingsTree{
  .v-treeview-node__root, .v-treeview-node{
    margin-top:0 !important;
    margin-bottom:4px !important;
    min-height: 30px !important;
  }
}
</style>
