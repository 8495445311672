<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab>Структура филиалов</v-tab>
        <v-tab>Специальности</v-tab>
        <v-tab>Шаблоны Мед.Карты</v-tab>
        <v-tab>Шаблоны документов</v-tab>
        <v-tab>Организации</v-tab>
      </v-tabs>
    </portal>
    <settings-divs v-if="tabSelected===0"/>
    <settings-spec v-if="tabSelected===1"/>
    <settings-med-record v-if="tabSelected===2"/>
    <settings-medias v-if="tabSelected===3"/>
    <insurs v-if="tabSelected===4"/>
  </div>
</template>

<script>
import SettingsDivs from "@/views/Settings/SettingsDivs";
import SettingsSpec from "@/views/Settings/SettingsSpec";
import SettingsMedRecord from "@/views/Settings/SettingsMedRecord";
import SettingsMedias from "@/views/Settings/SettingsMedias";
import Insurs from "@/views/Insur/Insurs.vue";
export default {
  components: {Insurs, SettingsMedias, SettingsMedRecord, SettingsSpec, SettingsDivs},
  data:()=>({
    tabSelected:null,
  }),
}
</script>