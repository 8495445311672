<template>
  <v-menu
      v-model="localOpen"
      :close-on-content-click="false"
      :open-on-hover="typeof openOnClick==='undefined'"
      :open-on-click="typeof openOnClick!=='undefined'"
      :open-delay="typeof delayLong==='undefined' ? 500 : 2000"
      :offset-y="typeof bottom!=='undefined'"
      :offset-x="typeof right!=='undefined'"
  >
    <template v-slot:activator="{ attrs, on }">
    <span v-bind="attrs" v-on="on">
      <slot name="default"/>
    </span>
    </template>
    <v-sheet :max-width="typeof width === 'undefined' ? 400 : width" :max-height="typeof height === 'undefined' ? 300 : height" class="pa-2">
      <v-row v-if="typeof openOnClick!=='undefined'" no-gutters align="center">
        <v-col class="ml-2 body-2">Информация</v-col>
        <v-col cols="auto">
          <v-btn @click="localOpen=false" icon small><v-icon small>mdi-close</v-icon></v-btn>
        </v-col>
      </v-row>
      <div class="caption">
        <slot name="content"/>
      </div>
    </v-sheet>
  </v-menu>
</template>
<script>
export default {
  props: [
    'openOnClick',
    'right',
    'bottom',
    'delayLong',
    'width',
    'height'
  ],
  data:()=>({
    localOpen:false,
  }),
  watch:{
    localOpen(){
      this.$emit('open', this.localOpen);
    }
  }
}
</script>