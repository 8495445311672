<template>
  <div class="pa-2">
    <div v-if="form.id==null" class="subtitle-1 mb-2">{{form.titleCreate}}</div>
    <div v-if="form.id!=null" class="subtitle-1">{{form.title}}</div>
    <div v-if="form.id!=null" class="pb-2 mt-n1 caption grey--text">#{{form.id}} {{form.subTitle}}</div>
    <v-divider/>
    <v-row class="mt-2">
      <div v-for="(input, key) in form.inputs" :key="key" :class="input.class+' '+input.classAdd">
        <v-text-field
            v-if="input.type==='text'"
            v-model="input.value"
            :label="input.label"
            type="text"
            outlined
            dense
            hide-details="auto"
        />
        <div v-if="input.type==='pickerColor'" class="pb-2 caption">
          {{input.label}}
        </div>
        <v-color-picker
            v-if="input.type==='pickerColor'"
            v-model="input.value"
            :mode.sync="pickerColorMode"
            :hide-canvas="false"
            hide-inputs
            :hide-sliders="false"
            show-swatches
            :swatches="pickerColorSwatches"
        />
        <v-btn
            v-if="input.type==='create'"
            @click="create"
            :loading="createDataState.isLoading"
            depressed
            color="primary"
        >
          {{input.label}}
        </v-btn>
        <v-btn
            v-if="input.type==='update'"
            @click="update"
            :loading="updateDataState.isLoading"
            depressed
            color="primary"
        >
          {{input.label}}
        </v-btn>
        <v-dialog v-if="input.type==='delete'" v-model="deleteDataState.isModalOpen" width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs" v-on="on"
                depressed
                color="red"
                dark
            >
              {{input.label}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Удалить?</v-card-title>
            <v-card-text></v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="deleteDataState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" class="ml-1" text @click="del()" :loading="deleteDataState.isLoading">Удалить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </div>
</template>

<script>


import axios from "axios";
import State from "@/plugins/state";
import colors from "vuetify/lib/util/colors";

export default {
  props:['form'],
  data: () => ({
    createDataState: new State(),
    updateDataState: new State(),
    deleteDataState: new State(),
    pickerColorMode: 'hexa',
    pickerColorSwatches: [],
    inputs: [],
  }),
  beforeUpdate(){
    this.inputsUpdate();
  },
  beforeMount() {
    this.inputsUpdate();
  },
  mounted() {
    let pickerColors = [];
    Object.keys(colors).map(key => {
      const color = colors[key];
      if ([
        'red',
        //'pink',
        //'purple',
        //'deepPurple',
        //'indigo',
        'blue',
        //'lightBlue',
        //'cyan',
        //'teal',
        'green',
        //'lightGreen',
        //'lime',
        //'yellow',
        'amber',
        //'orange',
        //'deepOrange',
        //'brown',
        //'blueGrey',
        'grey',
      ].indexOf(key) > -1)
        pickerColors.push([
          //color.darken4,
          //color.darken3,
          //color.darken2,
          //color.darken1,
          color.base,
          //color.lighten1,
          //color.lighten2,
          color.lighten3,
          //color.lighten4,
          //color.lighten5,
        ]);
    });
    this.pickerColorSwatches = pickerColors;
  },
  methods:{
    inputsUpdate(){
      for (let input in this.form.inputs){
        if (this.form.inputs[input].type=='create')
          this.form.inputs[input].classAdd = this.form.id!=null ? 'd-none' : '';
        else if (this.form.inputs[input].type=='update')
          this.form.inputs[input].classAdd = this.form.id==null ? 'd-none' : '';
        else if (this.form.inputs[input].type=='delete')
          this.form.inputs[input].classAdd = this.form.id==null ? 'd-none' : '';
        else
          this.form.inputs[input].classAdd = '';
      }
    },
    create(){
      let data = {};
      for(let key in this.form.inputs){
        let v = this.form.inputs[key];
        if (v.value!=null)
          if (v.value.length>0)
            data[key] = v.value;
      }
      if (this.form.paramsCreate!=null){
        for (let v in this.form.paramsCreate)
          data[v] = this.form.paramsCreate[v];
      }
      if (Object.keys(data).length!=0){
        this.createDataState.stateLoading();
        axios.post(this.form.urlCreate, data).then((res)=>{
          this.form.create(res.data);
          //this.$parent.dataUpdate(false);
          this.$emit('reload-event')
          this.createDataState.stateSuccess();
          this.$store.commit('alertSuccess', 'Успешно создано');

          //this.inputsUpdate();
        }).catch(()=>{
          this.createDataState.stateError();
        });
      }
    },
    update(){
      let data = {};
      for(let key in this.form.inputs){
        let v = this.form.inputs[key];
        if (v.value!=null)
          if (v.value.length>0)
            data[key] = v.value;
      }
      if (Object.keys(data).length!=0){
        this.updateDataState.stateLoading();
        axios.put(this.form.url, data).then((res)=>{
          this.form.update(res.data);
          //this.$parent.dataUpdate(false);
          this.$emit('reload-event')
          this.$store.commit('alertSuccess', 'Успешно сохранено');
          this.updateDataState.stateSuccess();
        }).catch(()=>{
          this.updateDataState.stateError();
        });
      }
    },
    del(){
      this.deleteDataState.stateLoading();
      axios.delete(this.form.url).then(()=>{
        this.form.delete();
        //this.$parent.dataUpdate(true);
        this.$emit('reload-event')
        this.$store.commit('alertSuccess', 'Успешно удалено');
        this.deleteDataState.stateSuccess();
        this.deleteDataState.modalClose();
        this.$emit('update:form', null);
      }).catch((e)=>{
        this.$store.commit('alertError', e.response.data.message);
        this.deleteDataState.stateError();
        this.deleteDataState.modalClose();
      });
    }
  }
}
</script>