<template>
  <v-sheet width="400">
    <v-simple-table class="tableSimple" dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th>День недели</th>
          <th>Время начала работы</th>
          <th>Время конца работы</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(v,i) in data" :key="i">
          <td>
            {{
              i===0 ? 'Понедельник' :
                  i===1 ? 'Вторник' :
                      i===2 ? 'Среда' :
                          i===3 ? 'Четверг' :
                              i===4 ? 'Пятница' :
                                  i===5 ? 'Суббота' :
                                      'Воскресенье'
            }}
          </td>
          <td class="text-no-wrap"><v-text-field v-model="v.begin" dense outlined class="ma-0" hide-details/></td>
          <td class="text-no-wrap"><v-text-field v-model="v.end" dense outlined class="ma-0" hide-details/></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>

<script>
export default {
  props: ['data']
}
</script>
