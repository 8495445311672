<template>
  <v-dialog
      v-model="loadState.isModalOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card class="grey lighten-4">
      <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2, 146, 77)' : 'rgb(40 40 40)'"  >
        <v-btn icon dark @click="$emit('update:medRecordTemplateId', null)">
          <v-progress-circular v-if="loadState.isLoading" indeterminate size="20" width="3"/>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="loadState.isSuccess">
          <div class="caption">Шаблон Мед.Записи</div>
          <div class="body-1">
            {{ medRecordTemplate.name }}
          </div>
        </v-toolbar-title>
        <v-spacer/>
        <div class="d-inline-block mr-2">
          <log entity-name="MedRecordTemplate" :entity-id="medRecordTemplateId"/>
        </div>
        <v-btn icon @click="updateState.modalOpen(medRecordTemplate);">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="copyState.modalOpen()" :loading="copyState.isLoading">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon @click="deleteState.modalOpen()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="loadState.isSuccess" class="pa-4">
        <v-card>
          <v-btn class="ml-4 mt-4 mb-2" depressed small @click="medRecordTemplateFieldGroupCreateState.modalOpen(medRecordTemplate, {id:Date.now(),name:null,sort:null,status:'active',medRecordTemplateFields:[]})">Добавить группу</v-btn>
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="medRecordTemplateFieldGroup in medRecordTemplate.medRecordTemplateFieldGroups"
              :key="'medRecordTemplateFieldGroup'+medRecordTemplateFieldGroup.id"
              class="mx-4"
            >
              <v-expansion-panel-header class="py-2 px-4">
                <div>
                  <div class="caption mb-1">Группа полей #{{medRecordTemplateFieldGroup.id}}{{medRecordTemplateFieldGroup.status==='deleted' ? " (Удалено)": ""}}</div>
                  <div>{{medRecordTemplateFieldGroup.name}}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="ml-n2">

                <v-btn class="my-2 mr-2" depressed small @click="medRecordTemplateFieldGroup.status==='active' ? medRecordTemplateFieldGroup.status='deleted' : medRecordTemplateFieldGroup.status='active'">{{medRecordTemplateFieldGroup.status==='active' ? 'Удалить группу' : 'Восстановить группу'}}</v-btn>
                <v-btn class="my-2" depressed small @click="medRecordTemplateFieldCreateState.modalOpen(medRecordTemplateFieldGroup, {id:Date.now(),name:null,sort:null,status:'active',medRecordTemplateFieldValues:[]})">Добавить поле</v-btn>

                <v-row>
                  <v-col cols="6" class="mt-4 mb-2">
                    <v-row>
                      <v-col cols="8">
                        <v-textarea label="Название" v-model="medRecordTemplateFieldGroup.name" :rules="formFieldRules.required" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-textarea label="Сортировка" v-model="medRecordTemplateFieldGroup.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


                <v-expansion-panels accordion>
                  <v-expansion-panel
                      v-for="medRecordTemplateField in medRecordTemplateFieldGroup.medRecordTemplateFields"
                      :key="'medRecordTemplateField'+medRecordTemplateField.id"
                  >
                    <v-expansion-panel-header class="py-2 px-4">
                      <div>
                        <div class="caption mb-1">Поле #{{medRecordTemplateField.id}}{{medRecordTemplateField.status==='deleted' ? " (Удалено)": ""}}</div>
                        <div>{{medRecordTemplateField.name==='' ? '(Безыменное поле)' : medRecordTemplateField.name}}</div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ml-n2">

                      <v-btn class="my-2 mr-2" depressed small @click="medRecordTemplateField.status==='active' ? medRecordTemplateField.status='deleted' : medRecordTemplateField.status='active'">{{medRecordTemplateField.status==='active' ? 'Удалить поле' : 'Восстановить поле'}}</v-btn>
                      <v-btn class="my-2" depressed small @click="medRecordTemplateFieldValueCreateState.modalOpen(medRecordTemplateField, {id:Date.now(),value:null,status:'active',type:'text',medRecordTemplateFieldValueOptions:[]})">Добавить значение</v-btn>

                      <v-row>
                        <v-col cols="6" class="mt-4 mb-2">
                          <v-row>
                            <v-col cols="8">
                              <v-textarea label="Название" v-model="medRecordTemplateField.name" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                              <v-textarea label="Сортировка" v-model="medRecordTemplateField.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                  v-model="medRecordTemplateField.value"
                                  label="По умолчанию"
                                  rows="1" outlined dense auto-grow hide-details="auto"
                                  :ref="'field'+medRecordTemplateField.id"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6">
                          <v-expansion-panels accordion>
                            <v-expansion-panel
                                v-for="medRecordTemplateFieldValue in medRecordTemplateField.medRecordTemplateFieldValues"
                                :key="'medRecordTemplateFieldValue'+medRecordTemplateFieldValue.id"
                                class="mx-4"
                            >
                              <v-expansion-panel-header class="py-2 px-4">
                                <div>
                                  <div class="caption mb-1">Значение #{{medRecordTemplateFieldValue.id}}{{medRecordTemplateFieldValue.status==='deleted' ? " (Удалено)": ""}}</div>
                                  <div>{{medRecordTemplateFieldValue.value}}</div>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="ml-n2">

                                <v-btn class="my-2 mr-2" depressed small @click="medRecordTemplateFieldValueState.modalOpen(medRecordTemplateFieldValue)">Изменить значение</v-btn>
                                <v-menu rounded="lg" offset-y>
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn v-on="on" depressed small class="my-2 mr-2">
                                      Тип значения ({{fieldValueType(medRecordTemplateFieldValue)}})
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                        v-for="medRecordTemplateFieldValueType in medRecordTemplateFieldValueTypes"
                                        :key="'medRecordTemplateFieldValueType'+medRecordTemplateFieldValueType.value"
                                        @click="medRecordTemplateFieldValue.type=medRecordTemplateFieldValueType.value"
                                    >
                                      <v-list-item-title>{{medRecordTemplateFieldValueType.name}}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                                <v-btn class="my-2 mr-2" depressed small @click="medRecordTemplateFieldValue.status==='active' ? medRecordTemplateFieldValue.status='deleted' : medRecordTemplateFieldValue.status='active'">{{medRecordTemplateFieldValue.status==='active' ? 'Удалить значение' : 'Восстановить значение'}}</v-btn>
                                <v-btn class="my-2" depressed small @click="medRecordTemplateFieldValueOptionCreateState.modalOpen(medRecordTemplateFieldValue, {id:Date.now(),value:null,sort:null,status:'active'})">Добавить вариант</v-btn>
                                <v-btn class="my-2" depressed small @click="insertAtCursor(medRecordTemplateField,'field'+medRecordTemplateField.id,'{{'+medRecordTemplateFieldValue.id+':'+medRecordTemplateFieldValue.value+'}}')">Добавить в текст</v-btn>
                                <div class="pt-4">
                                  <v-row>
                                    <v-col
                                        v-for="medRecordTemplateFieldValueOption in medRecordTemplateFieldValue.medRecordTemplateFieldValueOptions"
                                        :key="'medRecordTemplateFieldValueOption'+medRecordTemplateFieldValueOption.id"
                                        cols="6"
                                    >
                                      <v-textarea
                                          v-model="medRecordTemplateFieldValueOption.value"
                                          :readonly="medRecordTemplateFieldValueOption.status==='deleted'"
                                          @click:append="medRecordTemplateFieldValueOption.status==='active' ? medRecordTemplateFieldValueOption.status='deleted' : medRecordTemplateFieldValueOption.status='active'"
                                          :append-icon="medRecordTemplateFieldValueOption.status==='active' ? 'mdi-delete-outline' : 'mdi-delete-restore'"
                                          :label="'Вариант'+(medRecordTemplateFieldValueOption.status==='active' ? '' : ' (Удалено)')"
                                          rows="1" outlined dense auto-grow hide-details="auto"
                                      />
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider/>
          <v-btn color="primary" class="ma-4" small depressed @click="saveAction" :loading="saveState.isLoading">Сохранить</v-btn>
        </v-card>
      </div>
      <v-dialog v-model="copyState.isModalOpen" width="450">
        <v-card>
          <v-card-title>Скопировать шаблон мед.записи?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="copyState.modalClose()">Отмена</v-btn>
            <v-btn color="primary" text @click="copyAction" :loading="copyState.isLoading">Скопировать</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteState.isModalOpen" width="250">
        <v-card>
          <v-card-title>Удалить?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteState.modalClose()">Отмена</v-btn>
            <v-btn color="red" text @click="deleteAction" :loading="deleteState.isLoading">Удалить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updateState.isModalOpen" width="500">
        <v-card v-if="updateState.modalData!=null">
          <v-card-title>Изменить шаблон мед.записи</v-card-title>
          <v-card-text>
            <v-textarea label="Название" v-model="updateState.modalData.name" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            <v-textarea label="Сортировка" v-model="updateState.modalData.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="updateState.modalClose();">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="medRecordTemplateFieldGroupCreateState.isModalOpen" width="500">
        <v-form
            v-if="medRecordTemplateFieldGroupCreateState.modalData!=null"
            ref="medRecordTemplateFieldGroupCreateForm"
            @submit.prevent="(function(){
              if ($refs.medRecordTemplateFieldGroupCreateForm.validate()){
                medRecordTemplateFieldGroupCreateState.modalData.medRecordTemplateFieldGroups.push(medRecordTemplateFieldGroupCreateState.modalData1);
                medRecordTemplateFieldGroupCreateState.modalClose(null, null)
              }
            })()">
          <v-card>
            <v-card-title>Добавить группу</v-card-title>
            <v-card-text>
              <v-textarea label="Название" v-model="medRecordTemplateFieldGroupCreateState.modalData1.name" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
              <v-textarea label="Сортировка" v-model="medRecordTemplateFieldGroupCreateState.modalData1.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="medRecordTemplateFieldGroupCreateState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text type="submit">Добавить</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="medRecordTemplateFieldCreateState.isModalOpen" width="500">
        <v-form
            v-if="medRecordTemplateFieldCreateState.modalData!=null"
            ref="medRecordTemplateFieldCreateForm"
            @submit.prevent="(function(){
              if ($refs.medRecordTemplateFieldCreateForm.validate()){
                medRecordTemplateFieldCreateState.modalData.medRecordTemplateFields.push(medRecordTemplateFieldCreateState.modalData1);
                medRecordTemplateFieldCreateState.modalClose(null, null)
              }
            })()">
          <v-card>
            <v-card-title>Добавить поле</v-card-title>
            <v-card-text>
              <v-textarea label="Название" v-model="medRecordTemplateFieldCreateState.modalData1.name" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
              <v-textarea label="Сортировка" v-model="medRecordTemplateFieldCreateState.modalData1.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="medRecordTemplateFieldCreateState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text type="submit">Добавить</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="medRecordTemplateFieldValueCreateState.isModalOpen" width="500">
        <v-form
            v-if="medRecordTemplateFieldValueCreateState.modalData!=null"
            ref="medRecordTemplateFieldValueCreateForm"
            @submit.prevent="(function(){
              if ($refs.medRecordTemplateFieldValueCreateForm.validate()){
                medRecordTemplateFieldValueCreateState.modalData.medRecordTemplateFieldValues.push(medRecordTemplateFieldValueCreateState.modalData1);
                medRecordTemplateFieldValueCreateState.modalClose(null, null);
                globalValueSelected=null;
              }
            })()">
          <v-card>
            <v-card-title>Добавить значение</v-card-title>
            <v-card-text>
              <v-textarea  label="Значение" v-model="medRecordTemplateFieldValueCreateState.modalData1.value" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>

              <template v-if="globalMedRecordFieldValues">
                <span class="subtitle-1">Или выберите существующее:</span>
                <v-chip-group
                  active-class="primary--text"
                  column
                  v-model="globalValueSelected"
                >
                  <v-chip
                  v-for="value in globalMedRecordFieldValues"
                  :key="value.id"
                  
                  >
                  {{ value.value }}
                  </v-chip>
                </v-chip-group>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="medRecordTemplateFieldValueCreateState.modalClose();">Отмена</v-btn>
              <v-btn color="primary" text type="submit">Добавить</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="medRecordTemplateFieldValueState.isModalOpen" width="500">
        <v-card v-if="medRecordTemplateFieldValueState.modalData!=null">
          <v-card-title>Изменить значение</v-card-title>
          <v-card-text>
            <v-textarea label="Значение" v-model="medRecordTemplateFieldValueState.modalData.value" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="medRecordTemplateFieldValueState.modalClose()">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="medRecordTemplateFieldValueOptionCreateState.isModalOpen" width="500">
        <v-form
            v-if="medRecordTemplateFieldValueOptionCreateState.modalData!=null"
            ref="medRecordTemplateFieldValueOptionCreateForm"
            @submit.prevent="(function(){
              if ($refs.medRecordTemplateFieldValueOptionCreateForm.validate()){
                medRecordTemplateFieldValueOptionCreateState.modalData.medRecordTemplateFieldValueOptions.push(medRecordTemplateFieldValueOptionCreateState.modalData1);
                medRecordTemplateFieldValueOptionCreateState.modalClose(null, null)
              }
            })()">
          <v-card>
            <v-card-title>Добавить вариант</v-card-title>
            <v-card-text>
              <v-textarea label="Значение" v-model="medRecordTemplateFieldValueOptionCreateState.modalData1.value" :rules="formFieldRules.required" class="my-3" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
              <v-textarea label="Сортировка" v-model="medRecordTemplateFieldValueOptionCreateState.modalData1.sort" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="medRecordTemplateFieldValueOptionCreateState.modalClose()">Отмена</v-btn>
              <v-btn color="primary" text type="submit">Добавить</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import {FormFieldRules} from "@/plugins/formFieldRules";
import axios from "axios";

export default {
  components: {Log},
  props: ['medRecordTemplateId'],
  data: () => ({
    loadState: new State(),

    medRecordTemplate:null,
    formFieldRules: FormFieldRules,

    copyState: new State(),
    deleteState: new State(),
    saveState: new State(),
    updateState: new State(),

    medRecordTemplateFieldGroupCreateState: new State(),

    medRecordTemplateFieldCreateState: new State(),

    medRecordTemplateFieldValueCreateState: new State(),
    medRecordTemplateFieldValueState: new State(),
    medRecordTemplateFieldValueTypes:[
      {value:'number', name:'Число'},
      {value:'text', name:'Текст'},
      {value:'date', name:'Дата'},
      {value:'time', name:'Время'},
      {value:'file', name:'Файл'},
      {value:'icd', name:'МКБ'}
    ],

    medRecordTemplateFieldValueOptionCreateState: new State(),

    globalMedRecordFieldValues:null,
    globalValueSelected: []
  }),
  mounted(){
    axios.get(Api.host+'/medRecord/medRecord/value/list/')
      .then((r)=>{
          console.log(r.data.data);
          this.globalMedRecordFieldValues = r.data.data;
      })
      .catch((e)=>{
        console.log(e);
      });
},
  watch:{
    medRecordTemplateId(){
      if (this.medRecordTemplateId==null){
        this.loadState.modalClose();
      } else{
        this.load();
        this.loadState.modalOpen();
      }
    },
    globalValueSelected(){
      this.setGlobalValue();
    }
  },
  methods:{
    load(){
      this.loadState.stateLoading();
      Api.service.medRecord.medRecordTemplate(this.medRecordTemplateId, (data)=>{
        this.loadState.stateSuccess();
        this.medRecordTemplate = data;
        if (this.medRecordTemplate.status != 'active') {
          this.medRecordTemplate.name = 'Без названия'
        }
      }, ()=>{
        this.loadState.stateError();
      });
  
    },

    deleteAction(){
      this.deleteState.stateLoading();
      Api.service.medRecord.medRecordTemplateDelete(this.medRecordTemplateId, ()=>{
        this.deleteState.stateSuccess();
        this.$emit('update:medRecordTemplateId', null);
      }, ()=>{
        this.deleteState.stateError();
      });
    },
    saveAction(){
      this.saveState.stateLoading();
      Api.service.medRecord.medRecordTemplateUpdate(this.medRecordTemplate, ()=>{
        this.saveState.stateSuccess();
        //this.$emit('update:medRecordTemplateId', null);
        this.load();
      }, ()=>{
        this.saveState.stateError();
      });
    },
    fieldValueType(medRecordTemplateFieldValue){
      let n = this.medRecordTemplateFieldValueTypes.filter((v)=>v.value===medRecordTemplateFieldValue.type);
      if (n.length>0)
        return n[0].name;
      return this.medRecordTemplateFieldValueTypes.filter((v)=>v.value==='text')[0].name;
    },
    copyAction(){
      this.copyState.stateLoading();
      Api.service.medRecord.medRecordTemplateCopy(this.medRecordTemplate.id, (data)=>{
        this.copyState.stateSuccess();
        this.copyState.modalClose();
        this.loadState.modalClose();
        this.$store.commit('alertSuccess', 'Успешно скопировано');
        this.$emit('medRecordTemplateCopied', data.id);
      });
    },
    setGlobalValue(){
      this.medRecordTemplateFieldValueCreateState.modalData1 = this.globalMedRecordFieldValues[this.globalValueSelected];
    },
    insertAtCursor(medRecordTemplateField,link,text) {
      console.log(medRecordTemplateField);
      // Получаем ссылку на textarea
      const textarea = this.$refs[link][0].$refs.input; 
      // Получаем текущее положение каретки
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      // Вставляем текст в позицию каретки
      let inputText = textarea.value
      medRecordTemplateField.value = inputText.slice(0, start) + text + inputText.slice(end);

      // Восстанавливаем положение каретки
      textarea.selectionStart = start + text.length;
      textarea.selectionEnd = start + text.length;

      // Фокусируемся на textarea, чтобы каретка была видна
      textarea.focus();
    }

  }
}
</script>

