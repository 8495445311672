<template>
  <v-card class="ma-6">
    <v-card-text>
    <v-row>
      <v-col cols="3">
        <v-btn depressed small class="mb-2" color="primary" @click="create">Создать</v-btn>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            :server-items-length="itemsTotal"
            :loading="dataState.isLoading"
            hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr @click="sel(item)">
              <td class="text-no-wrap">{{item.id}}</td>
              <td class="text-no-wrap">
                <v-avatar :color="item.color" size="12" class="mr-2"/>
                <span>{{item.name}}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <v-divider class="float-left" vertical/>
        <div class="float-left pl-4 full-width">
          <settings-form v-if="form!=null" :form.sync="form" @reload-event="load()"/>
          <div v-else class="pa-2 subtitle-1 text-center grey--text">
            <v-icon class="mb-1" size="36" color="grey">mdi-information-outline</v-icon>
            <div>{{formText==null ? 'Выберите элемент из списка' : formText}}</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
  </v-card>
</template>

<script>
import State from "@/plugins/state";
import SettingsForm from "@/views/Settings/SettingsForm";
import Spec from "@/model/Spec";

export default {
  components: {SettingsForm},
  data: () => ({
    dataState: new State(),
    items: [],
    itemsTotal: 0,
    headers: [
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap', sortable:false},
      { text: 'Название', value: 'name', class:'text-no-wrap', sortable:false }
    ],
    formText: null,
    form: null,
    formSpec: {
      id:null,
      url:null,
      params:null,
      title:null,
      subTitle:null,
      urlCreate: '/api/spec/',
      titleCreate: 'Создать специальность',
      inputs: {
        name: {label: 'Название', value: '', class: 'col-3', type: 'text'},
        divider: {class: 'col-12 pa-0'},
        color: {label: 'Цвет', value: '#000000', class: 'col-12', type: 'pickerColor'},
        divider1: {class: 'col-12 pa-0'},
        create: {label: 'Создать', class: 'col-auto', type: 'create'},
        update: {label: 'Сохранить', class: 'col-auto', type: 'update'},
        delete: {label: 'Удалить', class: 'col-auto', type: 'delete'}
      },
      create: function(data){
        Spec.insertOrUpdate({data: data});
        this.read(data);
      },
      read:function(data){
        this.id = data.id;
        this.url = '/api/spec/'+data.id+'/';
        this.title = data.name;
        this.subTitle = 'Специальность';
        this.inputs.name.value = data.name;
        this.inputs.color.value = data.color==null ? '#000000' : data.color;
      },
      update:function(data){
        Spec.insertOrUpdate({data: data});
        data = Spec.query().find(this.id);
        data.name = this.inputs.name.value;
        data.color = this.inputs.color.value;
        Spec.insertOrUpdate({data: data});
        this.read(data);
      },
      delete:function(){
        Spec.delete(this.id);
      }
    },
  }),
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.dataState.stateLoading();
      Spec.deleteAll();
      Spec.api().get('/api/spec/')
        .then(()=> {
          this.dataUpdate();
          this.dataState.stateSuccess();
        })
        .catch((error)=> {
          this.dataState.stateError();
          console.log(error)
        });
    },
    dataUpdate(){
      this.items = Spec.query().get();
      this.itemsTotal = this.items.length;
    },
    sel(spec){
      this.formText = null;
      this.form = null;
      this.formSpec.read(spec);
      this.form = this.formSpec;
    },
    create(){
      this.formText = null;
      this.form = null;
      this.formSpec.read({});
      this.form = this.formSpec;
    }
  }
}
</script>
